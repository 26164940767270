var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"145px"}},[_c('label',{staticClass:"file-select mt-0 mb-3",class:{
      'default-round': !this.value && this.shape === 'round',
      'default-round-dark': !this.value && this.shape === 'rounddark',
      'default-rectangle': !this.value && this.shape === 'rectangle',
      'default-hero': !this.value && this.shape === 'hero',
      'default-square': !this.value && !['round', 'rectangle', 'hero', 'rounddark'].includes(this.shape),
      'mt-2': this.value && this.shape === 'rectangle',
      
    }},[(this.value)?_c('b-img',{class:{ round: this.shape === 'round' },attrs:{"src":_vm.bgImage,"fluid":"","alt":"Artist Banner Image"}}):_vm._e(),_c('input',{attrs:{"type":"file"},on:{"change":_vm.handleFileChange}})],1),_c('br')])}
var staticRenderFns = []

export { render, staticRenderFns }